import './App2.css';

function App() {
  return (
    <div className="App">
      Future site of MyToyCastle.com
    </div>
  );
}

export default App;
